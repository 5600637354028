<template>
  <div class="box">
    <div class="imgBox">
      <img src="../../assets/bind/success.png" />
    </div>
    <div class="tip">解绑成功</div>
    <div class="submit-btn" @click="submit">
      <span>确认</span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {
    submit() {
      WeixinJSBridge.call("closeWindow");
    },
  },
};
</script>

<style scoped lang="less">
.box {
  width: 100%;
  height: 100%;
  overflow: auto;
  background: #f5f5f5;
}
.imgBox {
  margin: 157px 210px 12px 210px;
  width: 330px;
  height: 330px;
  > img {
    width: 100%;
    height: 100%;
  }
}
.tip {
  color: #333333;
  font-size: 28px;
  text-align: center;
}
.submit-btn {
  width: 620px;
  height: 90px;
  line-height: 90px;
  text-align: center;
  background: #ef3535;
  border-radius: 45px;
  color: #ffffff;
  font-size: 32px;
  font-weight: 400;
  margin: 159px 65px 0 65px;
}
</style>
